import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useLastLocation = () => {
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState(null);

  useEffect(() => {
    setLastLocation(location.pathname);
  }, [location]);

  return lastLocation;
};

export default useLastLocation;
